import React, { useState, useEffect } from 'react';
import CustomTitle from '../components/UI/title/CustomTitle';
import MyButton from '../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import axios from 'axios';

const Name = ({ setButtonShow, setButtonText, setButtonStatus, setSignupBottom, setSelectedOption, error }) => {
    useEffect(() => {
        setButtonText("Continue");
        setButtonText("Continue");
        setButtonShow(true);
        setButtonStatus("");
        setSignupBottom('');
        setSelectedOption(null)

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'lead'
        });
    }, []);


    const [name, setName] = useState('');

    const handleInputChange = (event) => {
        const newValue = event.target.value.replace(/\s+/g, ''); // Удаляем все пробелы
        setName(newValue);
        localStorage.setItem('name', newValue)

        var profilesJSON = localStorage.getItem('business-profiles');
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function (profile) {
                profile.userName = newValue;
                profile.userPosition = "CEO";
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                userName: newValue,
                userPosition: "CEO"
            };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }
        if (newValue == "") {
            setSelectedOption(null)
            setButtonStatus("");
        } else {
            setSelectedOption('1')
            setButtonStatus("businessProfilesUpdate");
        }
    };









    return (
        <div style={{ paddingBottom: "24rem" }}>
            <CustomTitle style={{ marginBottom: "24rem" }} title="Glad to have you with us! What is your name?" />
            <div className={`input ${error ? 'error' : ''}`} >
                <input
                    type="text" placeholder='My name is...'
                    value={name}
                    onChange={handleInputChange}
                />
            </div>

            {/* <WithWizard
                render={({ next, previous, step, steps }) => (
                    <MyButton onClick={next} buttonText={'Continue'}>

                    </MyButton >
                )}
            /> */}



        </div>
    );
}

export default Name;
