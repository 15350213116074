import React, { useEffect, useState } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './home1.scss'
import { Helmet } from 'react-helmet';
import MyButton from '../../components/UI/button/MyButton';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
const Start = ({ sethomeShow, setButtonStatus, setBlockPaddingBottom, setHandShow, setButtonShow}) => {
  const history = useHistory();
  useEffect(() => {
    sethomeShow(true)
    setButtonStatus('white')
    setHandShow(false)
    setBlockPaddingBottom('0rem')
    setButtonShow(false)
  }, []);

  useEffect(() => {
    axios.get("https://staging.zeely.link/pages/landings", {
      headers: {
        "Content-Type": "application/json",
      },
      redirect: 'follow'
    })
      .then(response => {
        localStorage.setItem('HomeTitle', response.data.data.title)
        localStorage.setItem('HomeText', response.data.data.text)
        localStorage.setItem('HomeId', response.data.data.pageId)
        history.push(response.data.data.pageId);
      })
      .catch(error => {
        console.log(error)

      });
  }, []);

  return (
    <div>

    </div>

  );
}

export default Start;
