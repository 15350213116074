import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/App.scss';
import './styles/media.scss';
import { Provider } from 'react-redux';
import store from './store/store';
import Preload from './components/Preload/Preload';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Main = () => {

  const [screenLoading, setScreenLoading] = useState(false);

  useEffect(() => {
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(false);
    }, 1500);
  }, []);


  return (
    <div className="container">
      <Provider store={store}>
        {screenLoading && (
          <Preload />
        )}
        <App />
      </Provider>
    </div>
  );
};

root.render(<Main />);
