import React, { useState } from 'react';

const ProgressiveImage = ({ lowQualitySrc, highQualitySrc, handleImageLoad }) => {
    const [isHighQualityLoaded, setIsHighQualityLoaded] = useState(false);

    const handleHighQualityLoad = () => {
        setIsHighQualityLoaded(true);
        if (handleImageLoad) {
            handleImageLoad();
        }
    };

    return (
        <>
            <img
                src={lowQualitySrc}

                onLoad={handleImageLoad}
                style={{
                    width: '100%',
                    position: 'absolute',

                    opacity: isHighQualityLoaded ? 0 : 1,
                }}
            />
            <img
                src={highQualitySrc}

                onLoad={handleHighQualityLoad}
                style={{
                    width: '100%',
                    position: 'absolute',
                    opacity: isHighQualityLoaded ? 1 : 0,
                }}
            />
        </>

    );
};

export default ProgressiveImage;
