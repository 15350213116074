import React, { useEffect, useState } from 'react'
import './style.scss'

export default function Preload() {
    const [dots, setDots] = useState('');

    useEffect(() => {
      const interval = setInterval(() => {
        setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
      }, 500); 

      return () => clearInterval(interval);
    }, []);
    return (
        <div className="preload-loader">
            <span>Loading{dots}</span>
            <svg width="115" height="32" viewBox="0 0 115 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_14144_41167)">
                    <path d="M66.7355 16.0717C66.6996 13.3812 65.8004 11.157 64.0021 9.3991C62.2039 7.67713 59.938 6.81614 57.1687 6.81614C54.3994 6.81614 52.0976 7.67713 50.2993 9.3991C48.4651 11.157 47.5659 13.3453 47.5659 16.0359C47.5659 18.7265 48.4651 20.9148 50.2993 22.6368C52.0976 24.3587 54.3994 25.2197 57.1687 25.2197C59.3626 25.2197 61.3407 24.6099 63.067 23.3543C64.7934 22.0987 65.9083 20.5202 66.4118 18.583H61.7004C61.017 20.1973 59.2547 21.417 57.2047 21.417C54.759 21.417 52.8169 19.8027 52.2414 17.4709H66.5917C66.6996 17.0762 66.7355 16.6099 66.7355 16.0717ZM57.2047 10.6188C59.4705 10.6188 61.4486 11.9462 62.1679 13.9193H52.4213C53.1765 11.9103 55.0108 10.6188 57.2047 10.6188Z" fill="black" />
                    <path d="M87.1164 16.0717C87.0805 13.3812 86.1813 11.157 84.383 9.3991C82.5848 7.67713 80.319 6.81614 77.5496 6.81614C74.7803 6.81614 72.4785 7.67713 70.6802 9.3991C68.846 11.157 67.9468 13.3453 67.9468 16.0359C67.9468 18.7265 68.846 20.9148 70.6802 22.6368C72.4785 24.3587 74.7803 25.2197 77.5496 25.2197C79.7435 25.2197 81.7216 24.6099 83.448 23.3543C85.1743 22.0987 86.2892 20.5202 86.7927 18.583H82.0813C81.3979 20.1973 79.6356 21.417 77.5856 21.417C75.1399 21.417 73.1978 19.8027 72.6223 17.4709H86.9726C87.0805 17.0762 87.1164 16.6099 87.1164 16.0717ZM77.5856 10.6188C79.8514 10.6188 81.8295 11.9462 82.5488 13.9193H72.8022C73.5574 11.9103 75.3917 10.6188 77.5856 10.6188Z" fill="black" />
                    <path d="M89.2988 24.8969H93.9743V0H89.2988V24.8969Z" fill="black" />
                    <path d="M105.269 18.3318L100.306 7.17489H95.3067L103.075 22.9955L98.5077 32H103.399L115.016 7.17489H110.125L105.269 18.3318Z" fill="black" />
                    <path d="M41.9786 7.17578H47.3224L35.0767 24.8983H29.7329L41.9786 7.17578Z" fill="black" />
                    <path d="M30.2329 7.17578H43.349V11.172H30.2329V7.17578Z" fill="black" />
                    <path d="M33.8325 20.9004H46.4442V24.8966H33.8325V20.9004Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8458 19.5978H0.568753C0.475639 19.311 0.393345 19.0195 0.322353 18.7235H23.0922C23.0212 19.0195 22.9389 19.311 22.8458 19.5978ZM19.0931 25.0551H4.32144C3.98554 24.7824 3.66511 24.4914 3.36165 24.1837H20.0529C19.7494 24.4914 19.429 24.7824 19.0931 25.0551ZM2.58253 23.3108L2.58082 23.3087H20.8337L20.832 23.3108H2.58253ZM1.29642 21.34C1.49171 21.7177 1.70706 22.0835 1.94107 22.4358H21.4735C21.7075 22.0835 21.9228 21.7177 22.1181 21.34H1.29642ZM11.7073 27.6719C10.1332 27.6719 8.63175 27.3621 7.26081 26.8002H16.1537C14.7828 27.3621 13.2813 27.6719 11.7073 27.6719ZM0.0426891 16.9841C0.01839 16.6946 0.00468211 16.4021 0.00195312 16.107H23.4126C23.4099 16.4021 23.3962 16.6946 23.3719 16.9841H0.0426891ZM0.114948 14.3648C0.167012 13.9936 0.236574 13.6281 0.322776 13.2689H23.0918C23.178 13.6281 23.2475 13.9936 23.2996 14.3648H0.114948ZM0.569561 12.3939L0.568874 12.3961H22.8457L22.845 12.3939H0.569561ZM0.892626 11.5211C1.01629 11.2242 1.15188 10.9335 1.2988 10.6496H22.1157C22.2627 10.9335 22.3983 11.2242 22.5219 11.5211H0.892626ZM2.40839 8.90458C2.64226 8.60003 2.89084 8.30731 3.15305 8.02747H20.2615C20.5237 8.30731 20.7723 8.60003 21.0062 8.90458H2.40839ZM11.7073 4.32227C14.1117 4.32227 16.3469 5.0453 18.206 6.28526H5.2085C7.06767 5.0453 9.30281 4.32227 11.7073 4.32227Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0533 8.90501C10.5416 8.26906 11.094 7.68466 11.7009 7.16141H4.05426C3.44736 7.68466 2.89494 8.26906 2.40662 8.90501H10.0533ZM9.444 9.77789H1.79735C1.44738 10.3313 1.14275 10.9162 0.888639 11.5272H8.53529C8.7894 10.9162 9.09404 10.3313 9.444 9.77789ZM0.113443 14.3652C0.208142 13.6899 0.360762 13.0332 0.566133 12.4001H8.21278C8.0074 13.0332 7.85478 13.6899 7.76008 14.3652H0.113443ZM0.0243398 15.2381C0.00819606 15.489 0 15.7421 0 15.9971C0 16.3287 0.0138627 16.6571 0.0410416 16.9817H7.68767C7.66049 16.6571 7.64663 16.3287 7.64663 15.9971C7.64663 15.7421 7.65483 15.489 7.67098 15.2381H0.0243398ZM0.568369 19.601C0.384856 19.0365 0.243293 18.4531 0.147345 17.8546H7.79397C7.88992 18.4531 8.03148 19.0365 8.21499 19.601H0.568369ZM0.891504 20.4739C1.17901 21.164 1.53098 21.8206 1.93991 22.4363H9.58652C9.17759 21.8206 8.82563 21.164 8.53812 20.4739H0.891504ZM4.32052 25.0556C3.68067 24.536 3.09692 23.9504 2.57972 23.3091H10.2263C10.7435 23.9504 11.3273 24.536 11.9671 25.0556H4.32052ZM5.54889 25.9285C7.33754 27.0338 9.44697 27.6719 11.7058 27.6719C13.0446 27.6719 14.331 27.4478 15.5291 27.035C14.7057 26.7514 13.924 26.3787 13.1955 25.9285H5.54889ZM15.5292 4.9592C14.5736 5.28838 13.6742 5.7375 12.8488 6.28853H5.20216C7.0623 5.04657 9.29925 4.32227 11.7058 4.32227C13.0447 4.32227 14.331 4.54644 15.5292 4.9592Z" fill="black" />
                </g>
                <defs>
                    <clipPath id="clip0_14144_41167">
                        <rect width="115" height="32" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}
